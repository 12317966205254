import * as React from 'react';
import { Link } from '@mentimeter/ragnar-ui/link';

export function SupportLink() {
  return (
    <Link
      href="mailto:hello@mentimeter.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      support
    </Link>
  );
}
