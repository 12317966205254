import * as React from 'react';
import { P } from '@mentimeter/ragnar-ui/typography';
import { SupportLink, SSOErrorMessageDetails } from '..';

export function SSOGenericErrorMessage({
  requestId,
}: {
  requestId: string | undefined;
}) {
  return (
    <>
      <P color="onNegativeWeak" mb={2}>
        Something went wrong. Please try again. If you keep getting this error,
        contact <SupportLink /> for help and share this error code with the
        request ID.
      </P>
      <SSOErrorMessageDetails errorCode="unknown" requestId={requestId} />
    </>
  );
}
