import * as React from 'react';
import { Link } from '@mentimeter/ragnar-ui/link';

export function SSOHelpArticleLink({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Link href="https://help.mentimeter.com/en/articles/8703713-sso-log-in-troubleshooting-guide">
      {children}
    </Link>
  );
}
